html {
  background-color: #282c34;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.collapsible {
  width: 100%;  
}

p.content {
  text-align: justify;
  text-justify: inter-word;
}

ul.content, ol.content {
  text-align: justify;
}